/*
    Pure logic component to initiate the Firebase client
        - TODO: IREBASE_CONFIG needs to be secured & inaccessible by client run-time
*/
import firebase from "firebase";

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyATUKWerHfD5pkTtZGiUM4qg7JCvoHYbFM",
    authDomain: "summereyes.firebaseapp.com",
    databaseURL: "https://summereyes-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "summereyes",
    storageBucket: "summereyes.appspot.com",
    messagingSenderId: "399746239394",
    appId: "1:399746239394:web:42b1fd3850970e9e39c0fb"
}

export function firebaseClient() {
    if(!firebase.apps.length) {
        firebase.initializeApp(FIREBASE_CONFIG);
    }
}

export default firebase;