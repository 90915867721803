/*
    auth.js is used to provide authentication functionality throughout the application. It is to be used to wrap the _app component
    References key auth setup files, such as firebaseClient.js, firebaseAdmin.js
*/

// imports (external)
import React, {useState, useEffect, useContext, createContext} from 'react';
import nookies from 'nookies';
import firebase from 'firebase/app';
import "firebase/auth";

// imports (internal)
import { firebaseClient } from './FirebaseClient';

const AuthContext = createContext({}); // ensure the auth context is available to all child components in component tree

/*
    AuthProvider is the provider component to be utilized throughout auth func in the app.
    It checks for the presence of a user in the auth state whenever the IdToken changes, and provides the user object to all child components.
*/
export const AuthProvider = ({children}) => {
    firebaseClient();
    const [user, setUser] = useState(null); 

    useEffect(() => {
        return firebase.auth().onIdTokenChanged(async (user) => {
            if(!user){ 
                setUser(null);
                nookies.set(undefined, "token", "", {}); // clear the token cookie
                return;
            }

            const token = await user.getIdToken();
            setUser(user);
            nookies.set(undefined, "token", token, {});// add the token to cookies
        });
    }, []);

    return (<AuthContext.Provider value={{user}}>{children}</AuthContext.Provider>);
}

export const useAuth = () => useContext(AuthContext);